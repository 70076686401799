import React from "react"

const defaultState = {
  dark: false,
  wide: false,
  toggleDark: () => { },
  toggleWide: () => { }
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    dark: false,
    wide: false,
    loaded: false
  }

  toggleDark = () => {
    let dark = !this.state.dark
    typeof window !== 'undefined' && localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark })
  }

  toggleWide = () => {
    let wide = !this.state.wide
    typeof window !== 'undefined' && localStorage.setItem("wide", JSON.stringify(wide))
    this.setState({ wide })
  }

  componentDidMount() {
    const dark = JSON.parse(typeof window !== 'undefined' && localStorage.getItem("dark") === "true")
    const wide = JSON.parse(typeof window !== 'undefined' && localStorage.getItem("wide") === "true")
    this.setState({ dark, wide, loaded: true })

  }

  render() {
    const { children } = this.props
    const { dark, wide, loaded } = this.state
    return (
      <ThemeContext.Provider
        value={{
          dark,
          wide,
          loaded,
          toggleWide: this.toggleWide,
          toggleDark: this.toggleDark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }