// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/variables/__variables.scss"
import "./src/root.scss"

// Highlighting for code blocks
import "prismjs/themes/prism.css"


import React from "react"
import { ThemeProvider } from "./src/components/themecontext.jsx"
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)